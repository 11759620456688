<template>
   <div class="cashboxSetup">
       <div class="scroll-box">
            <div class="tr-li">
                <div class="lable-txt"></div>
                <div class="from-input"><el-checkbox v-model="isCashbox" label="启用钱箱功能" ></el-checkbox></div>
            </div>
            <div class="tr-li">
                <div class="lable-txt">钱箱连接打印机端口:</div>
                <div class="from-input">
                    <el-select v-model="port" placeholder="请选择" >
                        <el-option label="网口" value="网口" > </el-option>
                        <el-option label="USB" value="USB" > </el-option>
                        <el-option label="LPT1" value="LPT1" > </el-option>
                        <el-option label="LPT2" value="LPT2" > </el-option>
                        <el-option label="LPT3" value="LPT3" > </el-option>
                        <el-option :label="'COM'+index" :value="'COM'+index" v-for="index in 30" :key="index"> </el-option>
                       
                    </el-select>
                </div>
            </div>
            <div class="tr-li" v-if="port=='网口'">
                <div class="lable-txt">网口打印机IP地址:</div>
                <div class="from-input"> <input type="text" v-model="ip" ></div>
            </div>
            <div class="tr-li">
                <div class="lable-txt">钱箱开启指令参数1:</div>
                <div class="from-input"> <input-pattern type="text" pattern="number" :dplaces="0" :min="0" :max="255" v-model="param1" /></div>
            </div>
            <div class="tr-li">
                <div class="lable-txt">钱箱开启指令参数2:</div>
                <div class="from-input"> <input-pattern type="text" pattern="number" :dplaces="0" :min="0" :max="255" v-model="param2" /></div>
            </div>
            <div class="tr-li">
                <div class="lable-txt">钱箱开启指令参数3:</div>
                <div class="from-input flex-box">
                    <div class="flex1"><input-pattern type="text" pattern="number" :dplaces="0" :min="0" :max="255" v-model="param3" /></div>
                    <button class="bnt-open" @click="openCashBox()" :disabled="isOpen">测试开钱箱</button>
                </div>
            </div>
            <p class="explain">说明:一般指令参数如下几组:一组：0,70,255 二组：0,21,64 三组：0,100,250</p>
       </div>
        <div class="footer-box">
            <button class="btn" @click="back()">返回</button>
            <button class="btn selected" @click="saveConfig()">保存配置</button>
        </div>
   </div>
</template>

<script>
export default {
    name:'cashboxSetup',
    data(){
        return {
            //启用钱箱功能
            isCashbox:false,
            port:'网口',
            ip:'',//ip 地址
            param1:0,
            param2:0,
            param3:0,
            /**是否在开钱箱  */
            isOpen:false
        }
    },
    mounted(){
        this.$webBrowser.getCashbox().then(d=>{
            console.log(d)
            if(d){
                this.isCashbox=d.isCashbox;
                this.port=d.port;
                this.ip=d.ip;
                this.param1=d.param1;
                this.param2=d.param2;
                this.param3=d.param3;
            }
        })
    },
    methods:{
        /**退出 */
        back(){
            this.$emit("close");
        },
        /**保存配置 */
        saveConfig(){
            let data={
                isCashbox:this.isCashbox,
                port:this.port,
                ip:this.ip,
                param1:this.param1,
                param2:this.param2,
                param3:this.param3
            }
            if(data.port=='网口'){
                if(!data.ip){
                    this.$message.warning('网口打印机IP地址未设置！');
                    return;
                }
                if(!/^(([1-9]?\d|1\d{2}|2[0-4]\d|25[0-5])\.){3}([1-9]?\d|1\d{2}|2[0-4]\d|25[0-5])$/.test(data.ip)){
                    this.$message.warning('网口打印机IP地址格式不正确！');
                    return;
                }
            }
            if(data.param1==='' || data.param1==undefined || data.param1==null){
                this.$message.warning('指令参数1不能为空！');
                return;
            }
            if(data.param2==='' || data.param2==undefined || data.param2==null){
                this.$message.warning('指令参数2不能为空！');
                return;
            }
            if(data.param3==='' || data.param3==undefined || data.param3==null){
                this.$message.warning('指令参数3不能为空！');
                return;
            }
            this.$webBrowser.saveCashbox(data).then(d=>{
                if(d && d.state==0)
                this.$message.success("保存成功");
            });
            
        },
        openCashBox(){
            let data={
                port:this.port,
                ip:this.ip,
                param1:this.param1,
                param2:this.param2,
                param3:this.param3
            }
            if(data.port=='网口'){
                if(!data.ip){
                    this.$message.warning('网口打印机IP地址未设置！');
                    return;
                }
                if(!/^(([1-9]?\d|1\d{2}|2[0-4]\d|25[0-5])\.){3}([1-9]?\d|1\d{2}|2[0-4]\d|25[0-5])$/.test(data.ip)){
                    this.$message.warning('网口打印机IP地址格式不正确！');
                    return;
                }
            }
            if(data.param1==='' || data.param1==undefined || data.param1==null){ 
                this.$message.warning('指令参数1不能为空！');
                return;
            }
            if(data.param2==='' || data.param2==undefined || data.param2==null){
                this.$message.warning('指令参数2不能为空！');
                return;
            }
            if(data.param3==='' || data.param3==undefined || data.param3==null){
                this.$message.warning('指令参数3不能为空！');
                return;
            }
            this.isOpen=true;
            this.$webBrowser.openCashBox(this.port,this.ip,this.param1,this.param2,this.param3).then(d=>{
                this.isOpen=false;
                if(d?.state!=0){
                    this.$message.error('钱箱打开失败:'+(d?.message||""));
                }
            })
        }
    }
}
</script>

<style lang="scss">
@import './cashboxSetup.scss';
</style>